<template>
  <div id="app">
    <div v-if="$route.meta.keepAlive">
      <topNav></topNav>
      <router-view v-if="isRouterAlive"></router-view>
    </div>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>
<script>
import topNav from "../src/components/topNav/topNav.vue";
export default {
  // ...name:"commonNav",
  components: {
    topNav,
  },
  activated: function () {
    this.getCase();
  },
  name: "App",
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true,
    };
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  /* -webkit-font-smoothing: antialiased; */
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #101010;
}
* {
  margin: 0;
  padding: 0;
  border: 0;
}
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
