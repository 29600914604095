import axios from 'axios'
import {
  Message
} from 'element-ui'
import store from '../store'
import qs from 'qs'

// 创建axios实例
const service = axios.create({

  baseURL: process.env.NODE_ENV === 'development' ?
    '/api' : `${window.location.protocol}//${window.location.host}`,
  // baseURL: process.env.BASE_API, // api 的 base_url
  timeout: 10000 // 请求超时时间
})

// request拦截器
// service.interceptors.request.use(
//   config => {
//     if (store.getters.token) {
//       config.headers['X-Token'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
//     }
//     return config
//   },
//   error => {
//     // Do something with request error
//     console.log(error) // for debug
//     Promise.reject(error)
//   }
// )
service.interceptors.request.use(config => {
  if (config.method === 'post') {
    config.data = qs.stringify(config.data, {
      allowDots: true
    })
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
  }
  return config
})

// response 拦截器
service.interceptors.response.use(
  response => {
    /**
     * code为非20000是抛错 可结合自己业务进行修改
     */

    const res = response.data
    // 檔案下載
    if (res.type === 'application/octet-stream') {
      return res
    }
    if (res.status !== 200) {
      // 50008:非法的token; 50012:其他客户端登录了;  50014:Token 过期了;
      if (res.status === 401) {
        // MessageBox.confirm(
        //   '你已被登出，可以取消继续留在该页面，或者重新登录',
        //   '确定登出',
        //   {
        //     confirmButtonText: '重新登录',
        //     cancelButtonText: '取消',
        //     type: 'warning'
        //   }
        // ).then(() => {
        //   store.dispatch('FedLogOut').then(() => {
        //     location.reload() // 为了重新实例化vue-router对象 避免bug
        //   })
        // })
        setTimeout(() => {
          store.dispatch('FedLogOut').then(() => {
            location.reload() // 为了重新实例化vue-router对象 避免bug
          })
        }, 500)
      }

      Message({
        message: res.message,
        type: 'error',
        duration: 5 * 1000
      })
      return Promise.reject('error')
    } else {
      return response.data
    }
  },
  error => {
    console.log('err' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error).catch()
  }
)

export default service
