const pushRouter = {
  path: '/theme',
  component: () => import( /* webpackChunkName: "about" */ '@/views/app/index.vue'),
  meta: {
    keepAlive: true, //true显示头部
    title: 'App',
    alwaysShow: true,
    roles: ['admin', "paint"]
  },
  redirect: '/getTheme',
  children: [{
      path: '/getTheme',
      // name: 'appList',
      meta: {
        keepAlive: true, //true显示头部
        title: '主题管理',
        roles: ['admin', "paint"]
      },
      component: () => import( /* webpackChunkName: "about" */ '@/views/theme/list/list.vue'),
    },
    {
      path: '/getThemePush/:isApp/1',
      name: '主题推送列表管理',
      meta: {
        keepAlive: true, //true显示头部
        title: '主题推送列表管理',
        roles: ['admin', "paint"]
      },
      component: () => import( /* webpackChunkName: "about" */ '@/views/push/list/list.vue')
    },
    {
      path: '/getTheme/:key/1',
      name: '主题推送列表',
      meta: {
        keepAlive: true, //true显示头部
        title: '主题推送列表',
        roles: ['admin', "paint"]
      },
      component: () => import( /* webpackChunkName: "about" */ '@/views/push/pushAppList/list.vue')
    }, {
      path: '/updateThemePush/:appId/:isApp',
      name: '查看预推送信息',
      meta: {
        keepAlive: true, //true显示头部
        title: '应用管理',
        roles: ['admin', "paint"]
      },
      component: () => import( /* webpackChunkName: "about" */ '@/views/push/form/form.vue')
    },
  ]
}
export default pushRouter
