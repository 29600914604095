<template>
  <div>
    <a class="navbar-brand" @click="goPush">推送平台</a>
    <el-menu
      :default-active="this.$route.path"
      router
      mode="horizontal"
      background-color="#545c64"
      text-color="#fff"
      active-text-color="#ffd04b"
      class="el-menu-demo"
    >
      <el-menu-item v-for="(item, i) in navList" :key="i" :index="item.name">
        <template slot="title">
          <span> {{ item.navItem }}</span>
        </template>
      </el-menu-item>
      <a class="logout" @click="logout">LoginOut</a>
      <!-- <a>
        <el-menu>
          <el-dropdown-menu slot="dropdown" class="user-dropdown">
            <router-link class="inlineBlock" to="/">
              <el-dropdown-item> Home </el-dropdown-item>
            </router-link>
            <el-dropdown-item divided>
              <span style="display: block" @click="logout">LogOut</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-menu></a
      > -->
    </el-menu>
  </div>
</template>
<script>
export default {
  data() {
    return {
      appName: "",
      appId: 0,
      isApp: 1,
      navList: [
        { name: "/getApp", navItem: "应用管理" },
        {
          name: "/getPush/0",
          navItem: "应用推送",
        },
        { name: "/getTheme", navItem: "主题管理" },
        {
          name: "/getThemePush/1/1",
          navItem: "主题推送",
        },
        // { name: "/get", navItem: "应用推送" },
      ],
      id: 0,
    };
  },
  mounted() {},
  methods: {
    goPush() {
      this.$router.push({ path: "/getApp" });
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    logout() {
      localStorage.clear();
      this.$store.dispatch("LogOut").then((res) => {
        this.$router.push({ path: "/" });
        location.reload(); // 为了重新实例化vue-router对象 避免bug
      });
    },
  },
};
</script>
<style scoped>
.el-menu-demo {
  /* position: center; */
}
a:hover {
  cursor: pointer;
}
.navbar-brand {
  margin-left: 20%;
  color: #9d9d9d;
  position: relative;
  z-index: 999999;
  float: left;
  /* height: 50px; */
  padding: 15px 15px;
  font-size: 18px;
  /* line-height: 20px; */
  text-decoration: none;
}
.el-menu {
  border-right: solid 1px #e6e6e6;
  list-style: none;
  position: relative;
  margin: 0;
  padding-left: 35%;
  background-color: #fff;
}
.user-dropdown {
  margin-right: 10%;
  color: #c70d0d;
  position: relative;
  z-index: 999999;
  float: right;
  /* height: 50px; */
  padding: 15px 15px;
  font-size: 18px;
  /* line-height: 20px; */
  text-decoration: none;
}
.logout {
  margin-left: 20%;
  color: #9d9d9d;
  position: relative;
  z-index: 999999;
  float: left;
  /* height: 50px; */
  padding: 15px 15px;
  font-size: 18px;
  /* line-height: 20px; */
  text-decoration: none;
}
</style>