import {
  login,
  logout,
  getInfo
} from '@/apis/login'
import {
  getToken,
  setToken,
  removeToken
} from '@/utils/auth'
import {
  asyncRouterMap,
  constantRouterMap
} from '../../router/index'

function hasPermission(roles, route) {
  // debugger
  if (route.meta && route.meta.roles) {
    return roles.some(role => route.meta.roles.indexOf(role) >= 0)
  } else {
    return false
  }
}

function filterAsyncRouter(asyncRouterMap, roles) {
  debugger
  let accessedRouters = asyncRouterMap.filter(route => {
    if (hasPermission(roles, route)) {
      // debugger
      if (route.children && route.children.length) {
        route.children = filterAsyncRouter(route.children, roles)
        // console.log("accessedRouters now:" + accessedRouters)
      }
      return true
    }
    return false
  })
  return accessedRouters
}

const user = {
  state: {
    token: getToken(),
    name: '',
    avatar: '',
    roles: [],
    routers: constantRouterMap,
    addRouters: []
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_ROUTERS: (state, addRouters) => {
      state.addRouters = addRouters
      state.routers = constantRouterMap.concat(addRouters)
    }
  },

  actions: {
    // 登录
    Login({
      commit
    }, userInfo) {
      const username = userInfo.username.trim()
      return new Promise((resolve, reject) => {
        login(username, userInfo.password).then(response => {
          if (response.status === 200) {
            setToken(username)
            localStorage.setItem("username", username)
            localStorage.setItem("role", response.data)
            commit('SET_TOKEN', username)
            commit('SET_NAME', username)
          }
          // const data = response.data
          // setToken(data.token)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 获取用户信息
    GetInfo({
      commit,
      state
    }) {
      return new Promise((resolve, reject) => {
        getInfo(state.token).then(response => {
          const data = response.data
          if (data.roles && data.roles.length > 0) { // 验证返回的roles是否是一个非空数组
            // console.log("roles:" data.roles);
            commit('SET_ROLES', data.roles)
          } else {
            reject('getInfo: roles must be a non-null array !')
          }
          commit('SET_NAME', data.name)
          commit('SET_AVATAR', data.avatar)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },

    GenerateRoutes({
      commit
    }, data) {
      return new Promise(resolve => {
        const {
          roles
        } = data;
        let accessedRouters
        asyncRouterMap.push({
          path: '*',
          redirect: '/404',
          hidden: true
        })
        if (roles.indexOf('admin') >= 0) {
          debugger
          accessedRouters = asyncRouterMap
        } else {
          debugger
          accessedRouters = filterAsyncRouter(asyncRouterMap, roles)
        }

        commit('SET_ROUTERS', accessedRouters);
        resolve();
        return accessedRouters

      })
    },

    // 登出
    LogOut({
      commit,
      state
    }) {
      return new Promise((resolve, reject) => {
        logout().then(response => {
          commit('SET_TOKEN', '')
          commit('SET_ROLES', [])
          removeToken()
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 前端 登出
    FedLogOut({
      commit
    }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        commit('SET_NAME', '')
        removeToken()
        resolve()
      })
    }
  }
}

export default user
