 import Vue from 'vue'
 import VueRouter from 'vue-router'
 import Login from '../views/login/index.vue'
 import appRouter from './modules/appRouter'
 import themeRouter from './modules/themeRouter'
 import pushRouter from './modules/pushRouter'
 Vue.use(VueRouter)

 export const constantRouterMap = [{
     path: '/',
     meta: {
       keepAlive: false, //true显示头部
       title: '登陆'
     },
     component: Login,
   }, {
     path: '/login',
     meta: {
       keepAlive: false, //true显示头部
       title: '登陆'
     },
     component: Login,
   }, {
     path: '/404',
     component: () => import('@/views/404'),
     hidden: true
   },
   {
     path: '/errorLog/:id',
     component: () => import('@/views/errorLog'),
     hidden: true
   },
 ]


 export const asyncRouterMap = [
   appRouter, pushRouter, themeRouter
 ]
 export default new VueRouter({
   // mode: 'history', //后端支持可开
   scrollBehavior: () => ({
     y: 0
   }),
   routes: constantRouterMap
 })
 //  {
 //    path: '/getApp',
 //    meta: {
 //      keepAlive: true, //true显示头部
 //      title: 'App'
 //    },
 //    component: () => import( /* webpackChunkName: "about" */ '../views/app/list/list.vue'),
 //  },
 //  {
 //    path: '/updateApp/:id',
 //    name: '创建/更新app',
 //    meta: {
 //      keepAlive: true, //true显示头部
 //      title: 'App',
 //      roles: ['test']
 //    },
 //    component: () => import( /* webpackChunkName: "about" */ '../views/app/form/form.vue'),
 //  }, {
 //    path: '/getPush/:isApp',
 //    meta: {
 //      keepAlive: true, //true显示头部
 //      title: '推送列表管理'
 //    },
 //    component: () => import( /* webpackChunkName: "about" */ '../views/push/list/list.vue')
 //  },
 //  {
 //    path: '/getPush/:appId/:isApp',
 //    name: '应用推送列表管理',
 //    meta: {
 //      keepAlive: true, //true显示头部
 //      title: '应用推送列表管理'
 //    },
 //    component: () => import( /* webpackChunkName: "about" */ '../views/push/pushAppList/list.vue')
 //  }, {
 //    path: '/createPush/:appId/:isApp',
 //    name: '创建推送',
 //    meta: {
 //      keepAlive: true, //true显示头部
 //      title: '应用管理'
 //    },
 //    component: () => import( /* webpackChunkName: "about" */ '../views/push/form/form.vue')
 //  }, {
 //    path: '/updatePush/:pushId/:isApp',
 //    name: '查看推送信息',
 //    meta: {
 //      keepAlive: true, //true显示头部
 //      title: '应用管理'
 //    },
 //    component: () => import( /* webpackChunkName: "about" */ '../views/push/form/form.vue')
 //  },
 //  {
 //    path: '/getPushInfo/:pushId',
 //    name: '查看推送信息',
 //    meta: {
 //      keepAlive: true, //true显示头部
 //      title: '应用管理'
 //    },
 //    component: () => import( /* webpackChunkName: "about" */ '../views/push/info/info.vue')
 //  },
 //  {
 //    path: '/copyPushInfo/:pushId/:flag',
 //    name: '复制推送信息',
 //    meta: {
 //      keepAlive: true, //true显示头部
 //      title: '应用管理'
 //    },
 //    component: () => import( /* webpackChunkName: "about" */ '../views/push/form/form.vue')
 //  },
 //  {
 //    path: '/getTheme',
 //    // name: 'appList',
 //    meta: {
 //      keepAlive: true, //true显示头部
 //      title: 'App'
 //    },
 //    component: () => import( /* webpackChunkName: "about" */ '../views/theme/list/list.vue'),
 //  },
 //  {
 //    path: '/getThemePush/:isApp/1',
 //    name: '主题推送列表管理',
 //    meta: {
 //      keepAlive: true, //true显示头部
 //      title: '主题推送列表管理'
 //    },
 //    component: () => import( /* webpackChunkName: "about" */ '../views/push/list/list.vue')
 //  },
 //  {
 //    path: '/getTheme/:key/1',
 //    name: '主题推送列表',
 //    meta: {
 //      keepAlive: true, //true显示头部
 //      title: '主题推送列表'
 //    },
 //    component: () => import( /* webpackChunkName: "about" */ '../views/push/pushAppList/list.vue')
 //  }, {
 //    path: '/updateThemePush/:appId/:isApp',
 //    name: '查看预推送信息',
 //    meta: {
 //      keepAlive: true, //true显示头部
 //      title: '应用管理'
 //    },
 //    component: () => import( /* webpackChunkName: "about" */ '../views/push/form/form.vue')
 //  },
 //  ] 


 //  const router = new VueRouter({
 //    mode: 'history',
 //    scrollBehavior: () => ({
 //      y: 0
 //    }),
 //    routes: routes

 //    //  mode: 'hash',
 //    //  base: process.env.BASE_URL,

 //  })
 //  router.beforeEach((to, from, next) => {
 //    debugger
 //    if (to.path === "/") {
 //      next()
 //    } else {
 //      var token = localStorage.getItem("username")
 //      if (token) {
 //        next();
 //      } else {
 //        next({
 //          path: '/',
 //          query: {
 //            redirect: to.fullPath
 //          } // 将跳转的路由path作为参数，登录成功后跳转到该路由
 //        })
 //      }
 //    }
 //  })

 //  export default router
