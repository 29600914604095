const pushRouter = {
  path: '/push',
  component: () => import( /* webpackChunkName: "about" */ '@/views/app/index.vue'),
  meta: {
    keepAlive: true, //true显示头部
    title: 'App',
    alwaysShow: true,
    roles: ['admin', "paint", 'test', 'themekit_ios', 'themekit_android', 'paint_ios', 'intern', 'Z_intern']
  },
  redirect: '/getPush/:isApp',
  children: [{
      path: '/getPush/:isApp',
      meta: {
        keepAlive: true, //true显示头部
        title: '推送列表管理',
        roles: ['admin', "paint", 'test', 'themekit_ios', 'themekit_android', 'paint_ios', 'intern', 'Z_intern']
      },
      component: () => import( /* webpackChunkName: "about" */ '@/views/push/list/list.vue')
    },
    {
      path: '/getPush/:appId/:isApp',
      name: '应用推送列表管理',
      meta: {
        keepAlive: true, //true显示头部
        title: '应用推送列表管理',
        roles: ['admin', "paint", 'test', 'themekit_ios', 'themekit_android', 'paint_ios', 'intern', 'Z_intern']
      },
      component: () => import( /* webpackChunkName: "about" */ '@/views/push/pushAppList/list.vue')
    },
    {
      path: '/createPush/:appId/:isApp',
      name: '创建推送',
      meta: {
        keepAlive: true, //true显示头部
        title: '应用管理',
        roles: ['admin', "paint", 'test', 'themekit_ios', 'themekit_android', 'paint_ios', 'intern', 'Z_intern']
      },
      component: () => import( /* webpackChunkName: "about" */ '@/views/push/form/form.vue')
    },
    {
      path: '/updatePush/:pushId/:isApp',
      name: '查看推送信息',
      meta: {
        keepAlive: true, //true显示头部
        title: '应用管理',
        roles: ['admin', "paint", 'test', 'themekit_ios', 'themekit_android', 'paint_ios', 'intern', 'Z_intern']
      },
      component: () => import( /* webpackChunkName: "about" */ '@/views/push/form/form.vue')
    },
    {
      path: '/getPushInfo/:pushId',
      name: '查看推送信息',
      meta: {
        keepAlive: true, //true显示头部
        title: '应用管理',
        roles: ['admin', "paint", 'test', 'themekit_ios', 'themekit_android', 'paint_ios', 'intern', 'Z_intern']
      },
      component: () => import( /* webpackChunkName: "about" */ '@/views/push/info/info.vue')
    },
    {
      path: '/copyPushInfo/:pushId/:flag',
      name: '复制推送信息',
      meta: {
        keepAlive: true, //true显示头部
        title: '应用管理',
        roles: ['admin', "paint", 'test', 'themekit_ios', 'themekit_android', 'paint_ios', 'intern', 'Z_intern']
      },
      component: () => import( /* webpackChunkName: "about" */ '@/views/push/form/form.vue')
    }
  ]
}
export default pushRouter
