const appRouter = {
  path: '/app',
  component: () => import( /* webpackChunkName: "about" */ '@/views/app/index.vue'),
  meta: {
    keepAlive: true, //true显示头部
    title: 'App',
    alwaysShow: true,
    roles: ['admin', "paint", "themekit_android", "themekit_ios", "paint_ios", "intern",'test','Z_intern']
  },
  redirect: '/getApp',
  children: [{
      path: '/getApp',
      name: '获取appList',
      meta: {
        keepAlive: true, //true显示头部
        title: 'App',
        roles: ['admin', "paint", "themekit_android", "themekit_ios", "paint_ios", "intern",'test','Z_intern'],
        // roles: ['test']
      },
      component: () => import( /* webpackChunkName: "about" */ '@/views/app/list/list.vue'),
    },
    {
      path: '/updateApp/:id',
      name: '创建/更新app',
      meta: {
        keepAlive: true, //true显示头部
        title: 'App',
        roles: ['admin', "paint", 'themekit_android']
      },
      component: () => import( /* webpackChunkName: "about" */ '@/views/app/form/form.vue'),
    }
  ]
}
export default appRouter
